import { useSiteCategoriesWithTranslations } from "hooks/query/query-with-translations/use-site-categories-with-translations";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";
import { Caption, FormWrapper, FormColumn, Label } from "../styled";
import { CustomInput } from "../../../../../../components/custom-input";
import { CustomSelect } from "../../../components/custom-select";
import { IRegistrationStep } from "../hooks/use-step-data";
import { Phone } from "../phone";
import { useTranslation } from "react-i18next";
import { useLanguagesWithTranslations } from "hooks/query/query-with-translations/use-languages-with-translations";
import { ICountry } from "hooks/query/query-with-translations/use-countries-with-translations";
import { usePaymentSystemsForRegistration } from "hooks/sign-up/use-payment-systems-registration";

const HIDED_PAYMENT_METHOD_VALUE_FOR = ["USER_ACCOUNT"];

interface ISecondStep extends IRegistrationStep {
    countries: ICountry[];
    paymentSystemOptions: ReturnType<
        ReturnType<typeof usePaymentSystemsForRegistration>["getOptions"]
    >;
}

export const SecondStep = ({
    formik,
    countries,
    paymentSystemOptions,
}: ISecondStep) => {
    const [__] = useTranslation();
    const [DT] = useTranslation();

    const { siteCategories, loading: loadingSiteCategories } =
        useSiteCategoriesWithTranslations();
    const { agentSiteInformation } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    // только следующие языки должны присутствовать
    let { languages } = useLanguagesWithTranslations();

    languages = languages.filter(el => {
        const allowLanguages = new Set([
            "tr-TR",
            "ru-RU",
            "en-GB",
            "pt-PT",
            "ar-AE",
            "uz-UZ",
            "bn-BD",
            "es-ES",
        ]);
        return allowLanguages.has(el.value.code);
    });

    const isPaymentMethodValueVisible =
        formik.values.paymentSystem &&
        !HIDED_PAYMENT_METHOD_VALUE_FOR.includes(
            String(formik.values.paymentSystem.code),
        );

    return (
        <>
            <Caption>
                {format(
                    __(
                        "{name}, расскажите немного о трафике, с которым работаете. Уже сейчас вы можете выбрать удобный метод для вывода заработка",
                    ),
                    {
                        name: formik.values.firstname,
                    },
                )}
            </Caption>
            <FormWrapper>
                <FormColumn>
                    <CustomSelect
                        touched={formik.touched.websiteCategory}
                        errors={formik.errors.websiteCategory}
                        name="websiteCategory"
                        id="websiteCategory"
                        items={siteCategories}
                        curItem={siteCategories.find(
                            option =>
                                option.value.toString() ===
                                formik.values.websiteCategory?.toString(),
                        )}
                        placeholder={__("Тип трафика")}
                        onCurItemChange={async selectedItem =>
                            formik.setFieldValue(
                                "websiteCategory",
                                parseInt(selectedItem.value as string),
                            )
                        }
                        onBlur={formik.handleBlur}
                        value={formik.values.website}
                        disabled={loadingSiteCategories}
                        required
                    />
                    <Label htmlFor="country">{__("ГЕО Трафика")}</Label>
                    <CustomSelect
                        touched={formik.touched.country}
                        errors={formik.errors.country}
                        name="country"
                        id="country"
                        items={countries}
                        curItem={countries.find(
                            option => option.value === formik.values.country,
                        )}
                        onCurItemChange={async selectedItem => {
                            await formik.setFieldValue(
                                "country",
                                selectedItem.value,
                            );
                        }}
                        search
                        hint={__(
                            "Нам важно понимать, из какой страны будут ваши игроки",
                        )}
                    />
                    <CustomSelect
                        touched={!!formik.touched.paymentSystem}
                        name="paymentSystem"
                        id="paymentSystem"
                        items={paymentSystemOptions}
                        disabled={paymentSystemOptions.length === 1}
                        placeholder={__("Выбрать метод оплаты")}
                        curItem={paymentSystemOptions.find(
                            option =>
                                option.value.id ===
                                formik.values.paymentSystem?.id,
                        )}
                        onCurItemChange={async selectedItem => {
                            void formik.setFieldValue(
                                "paymentSystem",
                                selectedItem.value,
                            );
                        }}
                        required
                        hint={
                            formik.values.paymentSystem?.id === 51
                                ? format(
                                      __(
                                          "Это игровой счет на {companyName}, через который вы сможете выводить свои средства. С созданием вам сможет помочь ваш менеджер",
                                      ),
                                      {
                                          companyName:
                                              agentSiteInformation.companyName,
                                      },
                                  )
                                : ""
                        }
                    />
                    {formik.values.paymentSystem?.fields[0] &&
                        isPaymentMethodValueVisible && (
                            <CustomInput
                                touched={formik.touched.paymentSystem}
                                errors={
                                    (formik.errors.paymentSystem as any)
                                        ?.fields?.[0].value
                                }
                                name="paymentSystem.fields[0].id"
                                id="paymentSystem.fields[0].id"
                                type="text"
                                onChange={(e: any) => {
                                    void formik.setFieldValue(
                                        "paymentSystem.fields[0].value",
                                        e.target.value,
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                value={
                                    formik.values.paymentSystem?.fields[0].value
                                }
                                placeholder={
                                    formik.values.paymentSystem?.fields[0]
                                        .id === "51_133"
                                        ? "ID player account"
                                        : DT(
                                              formik.values.paymentSystem
                                                  ?.fields[0].name,
                                          )
                                }
                                required
                                hint={
                                    formik.values.paymentSystem.id === 51
                                        ? format(
                                              __(
                                                  "Чтобы получать комиссию, зарегистрируйтесь как игрок на сайте {companyName}. Далее добавьте İD игрового счета в это поле. Ваша комиссия будет приходить на него. Вывести ее можно любым методом на сайте {companyName}.",
                                              ),
                                              {
                                                  companyName:
                                                      agentSiteInformation.companyName,
                                              },
                                          )
                                        : ""
                                }
                            />
                        )}
                </FormColumn>
                <FormColumn>
                    <CustomInput
                        touched={formik.touched.website}
                        errors={formik.errors.website}
                        name="website"
                        id="website"
                        placeholder={
                            formik.values.websiteCategory === 21
                                ? __(
                                      "Пожалуйста, расскажите, откуда вы будете приводить клиентов",
                                  )
                                : __("Ссылка на ресурс")
                        }
                        forOther={formik.values.websiteCategory === 21}
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.website}
                    />
                    <Phone
                        touched={formik.touched.phone}
                        errors={formik.errors.phone}
                        setFieldValue={formik.setFieldValue}
                        values={formik.values}
                        name="phone"
                        id="phone"
                        placeholder={__("Номер телефона")}
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                    />
                    <CustomSelect
                        touched={formik.touched.language}
                        errors={formik.errors.language}
                        name="language"
                        id="language"
                        items={languages}
                        curItem={languages.find(
                            option => option.value === formik.values.language,
                        )}
                        placeholder={__("Предпочитаемый язык")}
                        onCurItemChange={async selectedItem =>
                            formik.setFieldValue("language", selectedItem.value)
                        }
                    />
                </FormColumn>
            </FormWrapper>
        </>
    );
};
