import { useEffect, useState } from "react";
import { Search } from "./search";
import { Info } from "../info";
import { IconFlag } from "./iconFlag";
import {
    CustomSelectWrapper,
    Select,
    Selected,
    SelectItem,
    ItemsWrapper,
    DropDownWrapper,
    Required,
    FormError,
} from "./styled";
import { ICountry } from "hooks/query/query-with-translations/use-countries-with-translations";

export interface IOption {
    value: unknown;
    label: string;
}
interface IProps extends React.InputHTMLAttributes<HTMLSelectElement> {
    touched?: boolean | undefined;
    errors?: string | undefined;
    items: IOption[];
    curItem?: IOption;
    required?: boolean;
    search?: boolean;
    hint?: string;
    disabled?: boolean;
    isCountrySelect?: boolean;
    onCurItemChange: (selectedItem: IOption | ICountry) => void;
}
export const CustomSelect = ({
    items,
    curItem,
    required,
    search,
    onCurItemChange,
    touched,
    errors,
    hint,
    disabled,
    isCountrySelect,
    ...props
}: IProps) => {
    const [active, setActive] = useState(false);
    const [filtredItems, setFiltredItems] = useState(items);
    let isValid = true;
    if (touched) {
        isValid = !errors;
    }

    useEffect(() => {
        setFiltredItems(items);
    }, [disabled, items]);

    const getSelected = () => {
        if (isCountrySelect && curItem) {
            const country = curItem as ICountry;

            return (
                <IconFlag
                    className={country?.value.code}
                    code={country?.value.code}
                />
            );
        }

        return curItem ? curItem.label : props.placeholder;
    };

    const getContent = (option: IOption | ICountry) => {
        if (isCountrySelect) {
            const country = option as ICountry;

            return (
                <>
                    <IconFlag
                        className={country.value.code}
                        code={country.value.code}
                    />{" "}
                    <span>
                        +{country.value.phoneCode} {country.label}
                    </span>
                </>
            );
        }

        return <span>{option.label}</span>;
    };

    const toggleActive = () => setActive(prev => !prev);

    return (
        <CustomSelectWrapper withCountries={isCountrySelect}>
            <Select {...props}>
                {filtredItems.map((item, i) => {
                    return (
                        <option value={i} key={i}>
                            {item.label}
                        </option>
                    );
                })}
            </Select>
            <Selected
                selected={!!curItem}
                onClick={() => {
                    if (disabled) {
                        return false;
                    }
                    toggleActive();
                }}
                isValid={isValid}
                active={active}
                withCountries={isCountrySelect}
                disabled={disabled}
            >
                {getSelected()}
            </Selected>
            <DropDownWrapper active={active} withCountries={isCountrySelect}>
                {search ||
                    (isCountrySelect && (
                        <Search items={items} setItems={setFiltredItems} />
                    ))}
                <ItemsWrapper
                    extended={!search}
                    hideScroll={filtredItems.length < 8}
                >
                    {filtredItems.map((item, id) => {
                        return (
                            curItem?.value !== item.value && (
                                <SelectItem
                                    onClick={() => {
                                        toggleActive();
                                        onCurItemChange(item);
                                    }}
                                    key={id}
                                >
                                    {getContent(item)}
                                </SelectItem>
                            )
                        );
                    })}
                </ItemsWrapper>
            </DropDownWrapper>
            {!!hint && <Info hint={hint} hintForSelect />}
            {required && <Required>*</Required>}
            {!isValid && <FormError>{errors}</FormError>}
        </CustomSelectWrapper>
    );
};
